/**
 * [Chart.PieceLabel.js]{@link https://github.com/emn178/Chart.PieceLabel.js}
 *
 * @version 0.4.0
 * @author Chen, Yi-Cyuan [emn178@gmail.com]
 * @copyright Chen, Yi-Cyuan 2017
 * @license MIT
 */
(function(){function e(){this.drawDataset=this.drawDataset.bind(this)}e.prototype.beforeDatasetsUpdate=function(a){if(this.parseOptions(a)&&"outside"===this.position){var b=1.5*this.fontSize+2;a.chartArea.top+=b;a.chartArea.bottom-=b}};e.prototype.afterDraw=function(a){this.parseOptions(a)&&(this.labelBounds=[],a.config.data.datasets.forEach(this.drawDataset),this.hasTooltip&&a.tooltip.draw())};e.prototype.drawDataset=function(a){for(var b=this.ctx,g=this.chartInstance,h=a._meta[Object.keys(a._meta)[0]],
p=0,m=0;m<h.data.length;m++){var c=h.data[m],d=c._view;this.hasTooltip&&c.draw();switch(this.mode){case "value":var f=a.data[m];this.format&&(f=this.format(f));f=f.toString();break;case "label":f=g.config.data.labels[m];break;default:f=d.circumference/this.options.circumference*100,f=parseFloat(f.toFixed(this.precision)),p+=f,100<p&&(f-=p-100,f=parseFloat(f.toFixed(this.precision))),f+="%"}b.save();b.beginPath();b.font=Chart.helpers.fontString(this.fontSize,this.fontStyle,this.fontFamily);if("outside"===
this.position||"border"===this.position&&"pie"===g.config.type){var k=d.outerRadius/2;var e,n=this.fontSize+2;var l=d.startAngle+(d.endAngle-d.startAngle)/2;"border"===this.position?e=(d.outerRadius-k)/2+k:"outside"===this.position&&(e=d.outerRadius-k+k+n);l={x:d.x+Math.cos(l)*e,y:d.y+Math.sin(l)*e};if("outside"===this.position){l.x=l.x<d.x?l.x-n:l.x+n;var q=d.outerRadius+n}}else k=d.innerRadius,l=c.tooltipPosition();if(this.arc)q||(q=(k+d.outerRadius)/2),b.fillStyle=this.fontColor,b.textBaseline=
"middle",this.drawArcText(f,q,d);else{k=b.measureText(f);d=l.x-k.width/2;k=l.x+k.width/2;var n=l.y-this.fontSize/2,r=l.y+this.fontSize/2;if("outside"===this.position?this.checkTextBound(d,k,n,r):c.inRange(d,n)&&c.inRange(d,r)&&c.inRange(k,n)&&c.inRange(k,r))b.fillStyle=this.fontColor,b.textBaseline="top",b.textAlign="center",b.fillText(f,l.x,l.y-this.fontSize/2)}b.restore()}};e.prototype.parseOptions=function(a){var b=a.options.pieceLabel;return b?(this.chartInstance=a,this.ctx=a.chart.ctx,this.options=
a.config.options,this.mode=b.mode,this.position=b.position||"default",this.arc=b.arc||!1,this.format=b.format,this.precision=b.precision||0,this.fontSize=b.fontSize||this.options.defaultFontSize,this.fontColor=b.fontColor||"#fff",this.fontStyle=b.fontStyle||this.options.defaultFontStyle,this.fontFamily=b.fontFamily||this.options.defaultFontFamily,this.hasTooltip=a.tooltip._active&&a.tooltip._active.length,!0):!1};e.prototype.checkTextBound=function(a,b,g,h){for(var p=this.labelBounds,m=0;m<p.length;++m)for(var c=
p[m],d=[[a,g],[a,h],[b,g],[b,h]],f=0;f<d.length;++f){var k=d[f][0],e=d[f][1];if(k>=c.left&&k<=c.right&&e>=c.top&&e<=c.bottom)return!1}p.push({left:a,right:b,top:g,bottom:h});return!0};e.prototype.drawArcText=function(a,b,g){var h=this.ctx,e=g.x,m=g.y,c=g.startAngle;g=g.endAngle;h.save();h.translate(e,m);m=g-c;c+=Math.PI/2;g+=Math.PI/2;e=h.measureText(a);c+=(g-(e.width/b+c))/2;if(!(g-c>m))for(h.rotate(c),c=0;c<a.length;c++)g=a.charAt(c),e=h.measureText(g),h.save(),h.translate(0,-1*b),h.fillText(g,
0,0),h.restore(),h.rotate(e.width/b);h.restore()};Chart.pluginService.register({beforeInit:function(a){a.pieceLabel=new e},beforeDatasetsUpdate:function(a){a.pieceLabel.beforeDatasetsUpdate(a)},afterDraw:function(a){a.pieceLabel.afterDraw(a)}})})();
